<template>
    <Header title="AboutUs" />
    <!-- BODY SECTION START -->
    <section class="inner_banner_section about_us_banner top_margin">
        <div class="inner_banner_row">
            <div class="inner_banner_col">
                <div class="inner_banner_block">
                    <p class="body_heading tc">About Us</p>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- ABOUT US PAGE SECTION ---------- -->
    <section class="about_us_page_section row_padding lef_img clud_bg">
        <div class="about_us_page_row main_container">
            <div class="about_us_img_col">
                <div class="about_us_img_block">
                    <div class="about_img_block">
                        <img :src="about_img" class="about_us_img">
                    </div>
                </div>
            </div>
            <div class="about_us_col">
                <div class="about_us_block">
                    <div class="body_heading_block desk_heading pdb_0">
                        <p class="body_heading">Welcome to Rio Colina</p>
                        <div class="small_body_font body_scroll pdb_0">
                            <p>Rio Colina is a group of premium and sophisticated villas that promises to deliver
                                experiences that are spirited, dynamic, and unique - a fit made for a perfect getaway. Set
                                in the raised terrain of a hill, our property is spread across 48 acres of lush greenery.
                                Overlooking the tapi river, this luxurious place exudes grandeur and elegance of the
                                highest stature. Hop on a sumptuous ride of 3-star amenities and experience a feeling like
                                never before! We leave no stone unturned to ensure your stay with us is a truly memorable
                                one in terms of service, dining, and entertainment. The soothing surroundings at our villa
                                will revive the senses dulled by the rigors of city life.</p>
                            <p>Savor the authentic taste of luxury and class with villa booking at Rio Colina.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- OUR VALUE SECTION ---------- -->
    <section class="our_value_section row_padding">
        <div class="our_value_row main_container">
            <div class="our_value_title_col">
                <div class="main_title_block">
                    <div class="body_heading_block desk_heading">
                        <p class="body_heading tc">Our values</p>
                    </div>
                </div>
            </div>
            <div class="our_value_point_col">
                <div class="wcu_data_block">
                    <div class="data_outter">
                        <div class="data_inner">
                            <div class="data_inner_inn">
                                <div class="data_img_box">
                                    <img :src="communication01" class="data_img">
                                </div>
                                <div class="data_info_box">
                                    <p class="data_sub_heading tc">Communication</p>
                                    <p class="small_body_font tc pdb_0">We make sure that your every need and wants are
                                        served in the best possible manner and communicated to us even before you say it!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="data_inner">
                            <div class="data_inner_inn">
                                <div class="data_img_box">
                                    <img :src="valuing_relationship2" class="data_img">
                                </div>
                                <div class="data_info_box">
                                    <p class="data_sub_heading tc">High-end standards</p>
                                    <p class="small_body_font tc">Our villas offer you high-end standards of style, comfort,
                                        and impeccable maintenance for a pleasant stay with your loved ones.</p>
                                </div>
                            </div>
                        </div>
                        <div class="data_inner">
                            <div class="data_inner_inn">
                                <div class="data_img_box">
                                    <img :src="passion03" class="data_img">
                                </div>
                                <div class="data_info_box">
                                    <p class="data_sub_heading tc">Passion</p>
                                    <p class="small_body_font tc">Our team of professionals has a passion to serve you in
                                        the best possible manner and make your getaway an unforgettable experience!</p>
                                </div>
                            </div>
                        </div>
                        <div class="data_inner">
                            <div class="data_inner_inn">
                                <div class="data_img_box">
                                    <img :src="excellent_service04" class="data_img">
                                </div>
                                <div class="data_info_box">
                                    <p class="data_sub_heading tc">Personalized Service</p>
                                    <p class="small_body_font tc pdb_0">Our 5-star amenities and immaculate personalized
                                        service will help you relax, unwind, and a hassle-free stay without any unpleasant
                                        surprises.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- FILLER SECTION ---------- -->
    <Download />
    <!-- ---------- ABOUT US PAGE SECTION ---------- -->
    <section class="about_us_page_2_section row_padding full_back_bg_img">
        <div class="about_us_page_2_row main_container">
            <div class="about_us_img_col">
                <div class="about_us_img_block">
                    <div class="about_img_block">
                        <img :src="about_us_01" class="about_us_img">
                    </div>
                </div>
            </div>
            <div class="about_us_col">
                <div class="about_us_block">
                    <div class="body_heading_block desk_heading">
                        <p class="cursive_font">A luxury of your dreams!</p>
                        <p class="body_heading">Experience intimacy with nature</p>
                        <div class="small_body_font body_scroll">
                            <p>In our fast-paced modern life, it truly sometimes becomes difficult to unwind - but we have
                                found the ultimate solution for you to relax and rejuvenate - Rio Colina. Nested in an
                                exclusive location overlooking the tapi river, it gives you the time to disconnect and
                                discover the beauty of the world around you. Experience the grandeur and magnificence of our
                                villas as you bask in the glory of lush greenery and scenic beauty.</p>
                            <p>If you are looking to host an event or just want to enjoy a getaway with your loved ones, we
                                have perfectly curated everything for you to have the best experience!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- ABOUT US FILLER SECTION ---------- -->
    <section class="about_us_page_filler_section row_padding">
        <div class="about_us_filler_row main_container">
            <div class="about_us_filler_col">
                <div class="about_us_filler_block">
                    <p class="body_heading tc">Thinking of buying your dream getaway villa?</p>
                    <div class="bird_hover_opacity bird bird--one">
                        <div class="block_cta">
                            <a href="tel:18008913918" class="site_button call_us_btn dark_bg_btn">
                                <div class="site_button_div">
                                    <!-- <img src="./images/header/call.png" class="btn_icon_img"> -->
                                    <!-- <svg class="btn_icon_img" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0171 5.67134C10.7394 5.67134 11.4321 5.95825 11.9428 6.46896C12.4535 6.97967 12.7404 7.67234 12.7404 8.3946C12.7404 8.63535 12.836 8.86624 13.0063 9.03647C13.1765 9.20671 13.4074 9.30235 13.6481 9.30235C13.8889 9.30235 14.1198 9.20671 14.29 9.03647C14.4603 8.86624 14.5559 8.63535 14.5559 8.3946C14.5559 7.19084 14.0777 6.03639 13.2265 5.18521C12.3753 4.33402 11.2209 3.85583 10.0171 3.85583C9.77638 3.85583 9.54549 3.95147 9.37525 4.12171C9.20501 4.29195 9.10938 4.52284 9.10938 4.76359C9.10938 5.00434 9.20501 5.23523 9.37525 5.40547C9.54549 5.5757 9.77638 5.67134 10.0171 5.67134Z" fill="black"></path>
                                    <path d="M10.0171 2.04036C11.7024 2.04036 13.3186 2.70982 14.5103 3.90148C15.7019 5.09314 16.3714 6.70937 16.3714 8.39462C16.3714 8.63538 16.467 8.86627 16.6373 9.0365C16.8075 9.20674 17.0384 9.30238 17.2791 9.30238C17.5199 9.30238 17.7508 9.20674 17.921 9.0365C18.0913 8.86627 18.1869 8.63538 18.1869 8.39462C18.1869 6.22787 17.3262 4.14985 15.794 2.61772C14.2619 1.08559 12.1839 0.224854 10.0171 0.224854C9.77638 0.224854 9.54549 0.320491 9.37525 0.490728C9.20501 0.660965 9.10938 0.891855 9.10938 1.13261C9.10938 1.37336 9.20501 1.60425 9.37525 1.77448C9.54549 1.94472 9.77638 2.04036 10.0171 2.04036Z" fill="black"></path>
                                    <path d="M17.9594 12.8517C17.9094 12.706 17.8232 12.5753 17.7089 12.4721C17.5946 12.3689 17.4558 12.2965 17.3058 12.2617L11.8593 11.018C11.7114 10.9845 11.5575 10.9885 11.4117 11.0298C11.2658 11.071 11.1326 11.1481 11.0241 11.254C10.897 11.3721 10.888 11.3811 10.2979 12.5067C8.34009 11.6046 6.77131 10.0294 5.87718 8.06783C7.03002 7.48687 7.0391 7.48687 7.15711 7.35071C7.26305 7.24228 7.34015 7.10905 7.38138 6.96317C7.42261 6.8173 7.42664 6.66342 7.39312 6.51558L6.1495 1.13261C6.11469 0.982551 6.04226 0.843823 5.93903 0.729484C5.83581 0.615144 5.70519 0.528953 5.55946 0.479024C5.34747 0.403308 5.12856 0.348581 4.90588 0.315629C4.67645 0.26243 4.44232 0.232024 4.20691 0.224854C3.09946 0.224854 2.03736 0.664788 1.25427 1.44788C0.471185 2.23096 0.03125 3.29306 0.03125 4.40051C0.0360538 8.1066 1.51042 11.6595 4.13102 14.2801C6.75163 16.9007 10.3045 18.3751 14.0106 18.3799C14.559 18.3799 15.102 18.2719 15.6086 18.062C16.1152 17.8522 16.5755 17.5446 16.9633 17.1569C17.351 16.7691 17.6586 16.3088 17.8684 15.8022C18.0783 15.2956 18.1863 14.7526 18.1863 14.2042C18.1866 13.9732 18.1684 13.7425 18.1318 13.5143C18.0937 13.2889 18.036 13.0672 17.9594 12.8517ZM14.0106 16.5644C10.7853 16.562 7.69278 15.2797 5.41213 12.999C3.13148 10.7184 1.84916 7.62584 1.84675 4.40051C1.84915 3.7753 2.09857 3.17637 2.54067 2.73427C2.98277 2.29218 3.58169 2.04275 4.20691 2.04036H4.50647L5.47776 6.25233L4.98758 6.5065C4.20691 6.91499 3.58964 7.24178 3.91643 7.94983C4.44858 9.45631 5.30962 10.8253 6.43702 11.9574C7.56441 13.0895 8.92984 13.9563 10.4341 14.4947C11.1966 14.8034 11.4962 14.2315 11.9047 13.4417L12.1679 12.9425L16.3708 13.9047V14.2042C16.3684 14.8295 16.119 15.4284 15.6769 15.8705C15.2348 16.3126 14.6359 16.562 14.0106 16.5644Z" fill="black"></path>
                                    </svg> -->
                                    <span class="btn_text">Let’s Connect!</span>
                                    <!-- <span class="btn_text">Call Us 18008 913918</span> -->
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ---------- TESTIMONIAL SECTION ---------- -->
    <section class="testimonial_section row_padding testi_bg_img">
        <div class="testimonial_row main_container">
            <div class="our_value_title_col">
                <div class="main_title_block">
                    <div class="body_heading_block desk_heading">
                        <p class="body_heading tc">Testimonials</p>
                    </div>
                </div>
            </div>
            <div class="testimonial_col">
                <div class="testimonial_block">
                    <carousel class="testimonial_outter" :settings="settings" :items-to-show="1" :wrap-around="true"
                        :navigation-enabled="true">
                        <slide key="1" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">Rio Colina resort it is a 5 star
                                        property. All the facilities
                                        are available in property . all type of food is very delicious . good service s all
                                        the service staff . i am two days staying in property . all the good facility are
                                        provided by KKB group . always all the senior person coaparate staff.</div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>Santosh Patel
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <slide key="2" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">It's a wonderful place for weekend
                                        gateway. They have nice
                                        villas. It's huge property with wonderful club house including all indoor activities
                                        like squash and badminton court, table tennis, snooker, carrom board, chess dart
                                        game and many more. They have infinity swimming pool and some water rides. Property
                                        is very well maintained. But the food is pathetic. So always arrange food in prior
                                        from outside or carry with you. Otherwise we really enjoyed the property.</div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>Manisha Mehta
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <slide key="3" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">Good place for vacation. There are
                                        varieties of option to
                                        chilled out. Atmosphere is very nice and peaceful. You can do many activities like
                                        swimming, playing pool, badminton, chess, cricket or squash. You get food here too.
                                        The place os suitable for photography too. Overall, nice place for your weekend.
                                    </div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>Dhaval Golakiya
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <slide key="4" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">We had a very good experience at
                                        this place. it is away from
                                        the crowded areas making it a peaceful and relaxing place to stay. The view from
                                        there is awesome. You can also enjoy a morning walk with the chirping sound of
                                        birds. We can also enjoy the pool and there are some rides for small kids so that
                                        they can have fun in the swimming pool.</div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>NIDHI DOSHI
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <slide key="5" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">Ideal place for enjoying one day
                                        picnic... reasonable rates
                                        for one day picnic..good food,nice ambience,nice swimming pool and courteous
                                        staff..food quality is also very good...must go place foe people living near Surat
                                        and bharuch...</div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>Vijay Chauhan
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <slide key="6" class="testimonial_item">
                            <div class="testimonial_inner">
                                <div class="testimonial_para_box">
                                    <div class="body_font tc box_title_text body_scroll">
                                        We have amazing experience over here.. Staff is so helpful and the food is too
                                        good.. Ambience is so vast and fine.. Outdoor games and activities have good areas..
                                    </div>
                                </div>
                                <div class="testimonial_name_box">
                                    <div class="body_font tc box_title_text">
                                        <div class="left_line"></div>Chaitali Shah
                                    </div>
                                </div>
                            </div>
                        </slide>
                        <template #addons>
                            <navigation />
                            <!-- <pagination /> -->
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </section>
    <!-- BODY SECTION END -->
    <Footer />
</template>

<script>
import filler_img from '../assets/images/filler_img.png';
// import GP_btn_01 from '../assets/images/GP_btn_01.png';
import GP_btn_01Png from '../assets/images/gp-img.png';
import AS_btn_01Png from '../assets/images/as-img.png';
import about_us_01 from '../assets/images/new_images/what_makes_us_unique.jpg';
// import about_us_01 from '../assets/images/inner_page/about_us_01.jpg';
import about_img from '../assets/images/inner_page/about_img.png';
import communication01 from '../assets/images/inner_page/01communication.png';
import valuing_relationship2 from '../assets/images/inner_page/02valuing_relationship.png';
import passion03 from '../assets/images/inner_page/03passion.png';
import excellent_service04 from '../assets/images/inner_page/04excellent_service.png';
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import left from '../assets/images/left.svg';
import right from '../assets/images/right.svg';

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

import Download from '@/pages/Download.vue'

export default {
    name: "About",
    components: {
        Header, Footer,
        Carousel,
        Slide,
        Navigation,
        Download
    },
    data() {
        return {
            app_android: process.env.VUE_APP_ANDROID,
            app_ios: process.env.VUE_APP_IOS,
            filler_img: filler_img,
            GP_btn_01Png: GP_btn_01Png,
            AS_btn_01Png: AS_btn_01Png,
            about_us_01: about_us_01,
            about_img: about_img,
            communication01: communication01,
            valuing_relationship2: valuing_relationship2,
            passion03: passion03,
            excellent_service04: excellent_service04,
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // nextLabel: "<div class='slick-next slider_arrows_block'><i class='fa fa-angle-right slider_arrow' aria-hidden='true'></i></div>",
            // prevLabel: "<div class='slick-prev slider_arrows_block'><svg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='25' cy='25' r='24' stroke='#A6A6A6' stroke-width='2'></circle><path d='M29.5 16L20 25.5L29.5 35' stroke='#A6A6A6' stroke-width='4'></path></svg></div>"
        };
    },
    mounted() {

        const addScript = document.createElement("script");
        addScript.setAttribute(
            "src",
            "./js/script.js"
        );
        document.head.appendChild(addScript);

        var pre = document.querySelector('.carousel__prev svg');
        pre.remove();
        var next = document.querySelector('.carousel__next svg');
        next.remove();
        var image = new Image();
        image.src = left;
        var pre1 = document.querySelector('.carousel__prev');
        pre1.appendChild(image)
        var image1 = new Image();
        image1.src = right;
        var next1 = document.querySelector('.carousel__next');
        next1.appendChild(image1)

        window.scrollTo({ top: 0, behavior: "smooth" });
    },
}
</script>
<style scoped>
.testimonial_inner {
    width: 916px !important;
    max-width: 916px !important;
    position: relative;
    margin: 60px 70px;
    display: block;
    /* align-items: center;
    max-width: 500px;
    margin: 0 auto; */
}

.testimonial_name_box {
    text-align: center;
    justify-content: center;
}

.carousel__prev,
.carousel__next {
    width: 40px !important;
    height: 40px !important;
    background-color: #000 !important;
    border-radius: 100%;
    top: 45%;
    z-index: 1;
    display: flex;
}

.carousel__prev svg,
.carousel__next svg {
    fill: white;
}

.box_title_text {
    font-weight: 500;
    font-size: 20px !important;
    color: #000000;
    line-height: 1.3;
}

.testimonial_para_box .box_title_text {
    font-weight: 400;
}


</style>
